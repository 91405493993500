export const WeeklyPlanData = [
    {
        path: "https://drive.google.com/file/d/1eCKMY42spxYjVFepcuzJLos_vnzkiLY8/view",
        title: `Week 1`,
        weekNumber: '1'
    },
    {
        path: "https://drive.google.com/file/d/1hy-yBBkCKBSi0LCgKUTt8at23SG4Ha0l/view",
        title: `Week 2`,
        weekNumber: '2'
    },
    {
        path: "https://drive.google.com/file/d/1jHG5O3BTEhTWNN7KvdcvhW5qgHv9djD-/view",
        title: `Week 3`,
        weekNumber: '3'
    },
]